import React, { useState } from "react";
import MainNaviPc from "./MainNaviPc";
import MainNavi from "./MainNavi";
import Header from "./Header";

export default function Contact(props) {
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener(
    "resize",
    function () {
      setWidth(window.innerWidth);
    },
    true
  );

  return (
    <>
      <Header />
      <div className="main">
        <MainNaviPc />
        <div className="main__main">
          <MainNavi />
          <div className="main__main-contents">
            <div className="inner">
              <div className="contact">
                <div className="contact__l">
                  <div className="txt --blk-300 --fz-ss">
                    回収依頼の変更など、お困りの事がありましたら以下よりお問い合わせくださいませ。
                  </div>
                  <div className="btn-layout">
                    <a href="mailto:pallet_station@mail.sanko-kk.co.jp" className="btn --primary">
                      <span>お問い合わせメール</span>
                    </a>
                  </div>
                </div>
                <div className="contact__r">
                  <div className="hdg --h2-txt">電話でのお問い合わせ</div>
                  <div className="btn-layout">
                    <a href="tel:0342123535" className="btn --primary">
                      <span>03-4212-3535</span>
                    </a>
                  </div>
                  <div className="txt --blk-300 --fz-ss">【受付時間】9：00～17：30（土・日・祝日は除く）</div>
                </div>
              </div>
              <div className="padding-for-sp">
                <div class="btn-layout">
                  <a
                    href={
                      width > 1023 ? "/assets/r03sdhy219/Manual_PC.pdf" : "/assets/r03sdhy219/Manual_SmartPhone.pdf"
                    }
                    target="_blank"
                    class="btn --wht-primary"
                    rel="noreferrer"
                  >
                    <span>使い方マニュアルをみる</span>
                  </a>
                </div>
              </div>
              <div class="padding-for-sp">
                <div className="hdg --h2-txt">三甲パレットレンタルwebサイト</div>
              </div>
              <div className="company">
                <a href="https://sanko-leasing.co.jp/" target="_blank" rel="noreferrer">
                  <img src="/assets/Images/bnr_sankolease.png" alt="三甲リース株式会社" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
