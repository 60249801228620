import React, { memo, useCallback, useContext, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { AppContext } from "../AppContext";
import Header from "./Header";
import MainNaviPc from "./MainNaviPc";
import { formatDate } from "../libs/date";
import { CANCEL_KBN, getMeishos, getTeikikaishuLabel, UNSO_CHECK_KBN } from "../libs/common";

const IraiItem = memo((props) => {
  const { irai } = props;
  const { meisais } = irai;
  const shohinNames = meisais.map((x) => x.shohinName).join("、");
  const shohinSuryo = meisais.reduce((total, n) => total + n.suryo, 0).toLocaleString();
  const displayKiboDate = () => {
    let label = "希望なし";
    if (!irai.kiboDateFrom && !irai.kiboDateTo) {
      return label;
    }
    if (irai.kiboDateFrom === irai.kiboDateTo) {
      label = formatDate(irai.kiboDateFrom, "M月D日(ddd)");
      return label;
    } else {
      label = formatDate(irai.kiboDateFrom, "M月D日(ddd)") + " ～ " + formatDate(irai.kiboDateTo, "M月D日(ddd)");
    }
    return label;
  };

  // 表示用希望時間
  const displayKiboTime = () => {
    let label = "希望なし";
    if (irai.kiboTimeBiko) {
      return irai.kiboTimeBiko;
    }
    if (!irai.kiboTimeFrom && !irai.kiboTimeTo) {
      return label;
    }
    label = formatDate("20210101" + irai.kiboTimeFrom, "H時") + "～" + formatDate("20210101" + irai.kiboTimeTo, "H時");
    if (label.indexOf("9") !== -1) {
      label = "午前 " + label;
    } else if (label.indexOf("17") !== -1) {
      label = "午後 " + label;
    }
    return label;
  };

  const ItemTag = useMemo(() => {
    let tagClass = "order__ico-primary",
      tagName = "依頼中";
    if (irai.binId !== 0) {
      tagName = "手配中";
    }
    if (irai.bin?.unsoCheckKbn === UNSO_CHECK_KBN.OK) {
      tagName = "配車手配済";
    }
    if (irai.idoStatus === "3") {
      tagName = "回収済";
      tagClass = "order__ico-blk";
    }
    if (irai.cancelKbn === CANCEL_KBN.CANCELTYU) {
      tagName = "キャンセル依頼中";
    }
    if (irai.cancelKbn === CANCEL_KBN.ZUMI) {
      tagName = "キャンセル済";
      tagClass = "order__ico-blk";
    }
    return { tagClass, tagName };
  }, [irai]);

  return (
    <a href="#" onClick={(e) => e.preventDefault()} className="arrangement" style={{ cursor: "default" }}>
      <div className="arrangement__summary">
        <div className="arrangement__pickup">回収日時</div>
        <div className={"arrangement__date --mikakutei"}>
          <span className={displayKiboDate() ? "txt --blk-400" : ""}>{displayKiboDate() || "未確定"}</span>
        </div>
        <div className={"arrangement__biko --mikakutei"}>
          <span className={displayKiboTime() ? "txt --blk-400" : ""}>{displayKiboTime() || "-"}</span>
        </div>
      </div>
      <div className="arrangement__detail">
        <div className="order">
          <div className="order__ico">
            <span className={ItemTag.tagClass}>{ItemTag.tagName}</span>
            {irai.teikiFlg === "1" && <span className="order__ico-blk">定期回収</span>}
          </div>
        </div>
        <div className="confirm-layouts">
          <div className="confirm-layout">
            <div className="confirm-layout__title">回収パレット数</div>
            <div className="confirm-layout__detail">
              <span className="txt --blk-400">{shohinSuryo}枚</span>
            </div>
          </div>
          <div className="confirm-layout">
            <div className="confirm-layout__title">パレットの種類</div>
            <div className="confirm-layout__detail txt --blk-400">
              <span className="txt --blk-400">{shohinNames}</span>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
});

export default function Dashboard() {
  const { me, meishos, teikis } = useContext(AppContext);
  const { kokyaku } = me;

  // 名称マスタ
  const niyakuList = useMemo(() => getMeishos(meishos, "NIYAKU_KBN"), [meishos]);
  const youbis = useMemo(() => getMeishos(meishos, "YOUBI_KBN"), [meishos]);
  const kaishuTimes = useMemo(() => getMeishos(meishos, "TONYA_TIME_KBN"), [meishos]);

  // STATE
  const [order, setOrder] = useState("1");
  const [data, setData] = useState();
  const { zaikoSuryo = 0, irais = [], iraityuNum = 0, iraicancelNum = 0, tehaizumiNum = 0, kojins = [] } = data || {};

  // 回収条件
  const kaishuYobis = ((me.kaishuYoubiKbns || "").split(",") || [])
    .map((x) => (kaishuTimes.find((z) => z.kbn === x) || {}).name)
    .join("・");
  const kaishuTime =
    me.kaishuTimeKbn === "3" ? me.kaishuTimeBiko : (kaishuTimes.find((y) => y.kbn === me.kaishuTimeKbn) || {}).name;
  const hokanGendo = (me.hokanGendo || 0).toLocaleString();
  const nieki = (niyakuList.find((y) => y.kbn === me.niyakuKbn) || {}).name;
  const fileExist = me.files && me.files.length ? "登録あり" : "登録なし";
  const chuijiko = me.biko;
  const zaikoBar = Math.round((zaikoSuryo * 100) / parseInt(hokanGendo.replace(/,/g, ""), 10)) + "%";
  const checkBar = Math.round((zaikoSuryo * 100) / parseInt(hokanGendo.replace(/,/g, ""), 10));

  // 定期回収条件
  const dispTeikiKaishu = useMemo(() => getTeikikaishuLabel(teikis, youbis), [teikis, youbis]);

  const getData = useCallback(async () => {
    try {
      const res = await axios.get("/api/dashboard?order=" + order);
      if (res && res.data) {
        setData(res.data);
      }
    } catch (error) {
      console.warn(error);
    }
  }, [order]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Header />
      <div className="main">
        <MainNaviPc current="/dashboard" />

        <div className="main__main">
          <div className="main__main-header"></div>
          <div className="main__main-contents">
            <div className="inner --fix">
              <div className="dashboard">
                <div>
                  <a
                    href={"/assets/r03sdhy219/【SPR】2024年　年末年始休業案内.pdf"}
                    target="_blank"
                    class="hdg__note2"
                    rel="noreferrer"
                  >
                    <span>年末年始の休業ご案内</span>
                  </a>
                </div>
                <div className="dashboard__header">
                  <div className="dashboard__company">
                    <div>{kokyaku?.ryaku ? kokyaku.ryaku : kokyaku.name} 様</div>
                    <div className="ico --calendar">{`定期回収 [ ${dispTeikiKaishu} ]`}</div>
                  </div>
                  <div className="dashboard__btn">
                    <Link to="/irai" className="btn --primary">
                      <span>新規回収依頼</span>
                    </Link>
                  </div>
                </div>
                <div className="dashboard__status">
                  <div>
                    <div>依頼中</div>
                    <div>{iraityuNum.toLocaleString()}</div>
                  </div>
                  <div>
                    <div>キャンセル依頼中</div>
                    <div>{iraicancelNum.toLocaleString()}</div>
                  </div>
                  <div>
                    <div>配車手配済</div>
                    <div>{tehaizumiNum.toLocaleString()}</div>
                  </div>
                  <div>
                    <div>現在の在庫</div>
                    <div>
                      {zaikoSuryo.toLocaleString()}
                      <span>枚</span>
                    </div>
                    <div>
                      <div className="zaiko-bar">
                        <div className="zaiko-bar__bar" style={{ width: checkBar > 0 ? zaikoBar : "0%" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="arrangements">
                <div className="arrangement__header">
                  <div className="arrangement__title">手配状況</div>
                  <div className="arrangement__sort">
                    <div className="sort">
                      <div className="sort__title">並び替え</div>
                      <div className={`sort__sort ${order === "1" && "--current"}`}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setOrder("1");
                          }}
                        >
                          新しい依頼順
                        </a>
                      </div>
                      <div className={`sort__sort ${order === "2" && "--current"}`}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setOrder("2");
                          }}
                        >
                          回収日が近い順
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="arrangement__body">
                  {irais && irais.map((irai) => <IraiItem key={irai.id} irai={irai} />)}
                </div>
                <div className="btn-layout --col-1">
                  <div>
                    <Link to="/tehai-jyokyo" className="btn --h-s --wht-primary">
                      <span>手配状況一覧をみる</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="main__main-footer"></div> */}
        </div>
        <div className="main__placeholder-r isOpen">
          <div className="dashboard-setting">
            <div className="dashboard-setting__title">回収条件</div>
            <div className="dashboard-setting__body">
              <div className="confirm-layouts">
                <div className="confirm-layout">
                  <div className="confirm-layout__title">回収可能な曜日</div>
                  <div className="confirm-layout__detail">{kaishuYobis}</div>
                </div>
                <div className="confirm-layout">
                  <div className="confirm-layout__title">回収可能な時間帯</div>
                  <div className="confirm-layout__detail">{kaishuTime}</div>
                </div>
                <div className="confirm-layout">
                  <div className="confirm-layout__title">保管限度枚数</div>
                  <div className="confirm-layout__detail">{hokanGendo || "－"}枚</div>
                </div>
                <div className="confirm-layout" hidden>
                  <div className="confirm-layout__title">荷役</div>
                  <div className="confirm-layout__detail">{nieki || "－"} </div>
                </div>
                <div className="confirm-layout">
                  <div className="confirm-layout__title">回収場所の写真</div>
                  <div className="confirm-layout__detail">{fileExist}</div>
                </div>
                <div className="confirm-layout">
                  <div className="confirm-layout__title">注意事項</div>
                  <div className="confirm-layout__detail">{chuijiko}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-setting">
            <div className="dashboard-setting__title">個人設定</div>
            <div className="dashboard-setting__body">
              {kojins.map((kojin) => (
                <React.Fragment key={kojin.id}>
                  <span>・{kojin.name}</span>
                  <br />
                </React.Fragment>
              ))}
            </div>
            <div className="btn-layout --col-1">
              <div>
                <Link to="/mypage" className="btn --h-s --wht-secondary">
                  <span>回収条件・個人設定の変更</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
