import React, { memo, useContext, useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";

import { AppContext } from "../AppContext";
import {
  KIBOBI_KBN,
  KIBOTIME_KBN,
  NOTIFY_KBN,
  KIBOSHASHU_KBN,
  NIYAKU_KBN,
  createErrorMessage,
  getMeishos,
} from "../libs/common";
import {
  formatDate,
  twoDaysAfter,
  threeDaysAfter,
  isCompareDay,
  harfYearAfter,
  getYasumiakeDay,
  getSecondDay,
} from "../libs/date";
import { useBoolean } from "../hooks/useBoolean";

import InfoModal from "../modals/InfoModal";
import ErrorModal from "../modals/ErrorModal";
import MainNaviPc from "./MainNaviPc";
import MainNavi from "./MainNavi";
import axios from "axios";
import KigenCalendarPanel from "../panes/KigenCalendarPanel";
import KikanCalendarPanel from "../panes/KikanCalendarPanel";
import { TextInput } from "../components/TextInput";
import { CheckBox } from "../components/CheckBox";

const IraiForm = memo((props) => {
  const { isHavi, item } = props;
  const { me = {}, meishos, shohins, holidays } = useContext(AppContext);

  const ngShakakuList = useMemo(() => getMeishos(meishos, "SHAKAKU_KBN"), [meishos]);
  const kiboShashuList = useMemo(() => getMeishos(meishos, "TONYA_SHASHU_KBN"), [meishos]);
  const kiboTimeList = useMemo(() => getMeishos(meishos, "TONYA_TIME_KBN"), [meishos]);
  const niyakuList = useMemo(() => getMeishos(meishos, "NIYAKU_KBN"), [meishos]);

  const history = useHistory();

  const TB_KiboTimeBiko = useRef();
  const inputFileRef = useRef();
  const [changeBiko, setChangeBiko] = useState("");
  const [files] = useState(me?.files || []);
  const [selectItem, setSelectItem] = useState(null);
  const [tehaiList, setTehaiList] = useState(null);

  // 共通モーダル制御
  const [isInfoModal, infoModalControl] = useBoolean(false);
  const [message, setMessage] = useState("");
  const refOkEvent = useRef(null);
  const [isErrorModal, errorModalControl] = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState("");

  // State項目
  const [kaishuSuryo, setKaishuSuryo] = useState(0);

  const [selectKaishuShubetu, setSelectKaishuShubetu] = useState([]);
  const [kaishuShubetu, setKaishuShubetu] = useState([]);

  const [selectKiboDateFrom, setSelectKiboDateFrom] = useState("");
  const [selectKiboDateTo, setSelectKiboDateTo] = useState("");
  const [selectKiboDateType, setSelectKiboDateType] = useState(KIBOBI_KBN.NONE);
  const [kiboDateFrom, setKiboDateFrom] = useState("");
  const [kiboDateTo, setKiboDateTo] = useState("");

  const [selectKiboTimeFrom, setSelectKiboTimeFrom] = useState(me ? me.kaishuTimeFrom : "");
  const [selectKiboTimeTo, setSelectKiboTimeTo] = useState(me ? me.kaishuTimeFrom : "");
  const [selectKiboTimeType, setSelectKiboTimeType] = useState(me ? me.kaishuTimeKbn : KIBOTIME_KBN.NONE);
  const [kiboTimeFrom, setKiboTimeFrom] = useState(me ? me.kaishuTimeFrom : "");
  const [kiboTimeTo, setKiboTimeTo] = useState(me ? me.kaishuTimeTo : "");
  const [kiboTimeKbn, setKiboTimeKbn] = useState(me ? me.kaishuTimeKbn : KIBOTIME_KBN.NONE);

  const [selectKiboTimeBiko, setSelectKiboTimeBiko] = useState(me ? me.kaishuTimeBiko : "");
  const [kiboTimeBiko, setKiboTimeBiko] = useState(me ? me.kaishuTimeBiko : "");

  const [selectNgShakaku, setSelectNgShakaku] = useState(me ? me.ngShakakuKbns?.split(",") : []);
  const [kiboNgShakakuKbns, setKiboNgShakakuKbns] = useState(me ? me.ngShakakuKbns?.split(",") : []);

  const [selectKiboShashuType, setSelectKiboShashuType] = useState(me ? me.sharyoKbn : KIBOSHASHU_KBN.NONE);
  const [kiboSharyoKbn, setKiboSharyoKbn] = useState(me ? me.sharyoKbn : KIBOSHASHU_KBN.NONE);

  const [selectNiyaku, setSelectNiyaku] = useState(me ? me.niyakuKbn : NIYAKU_KBN.COMMON);
  const [kiboNiyakuKbn, setKiboNiyakuKbn] = useState(me ? me.niyakuKbn : NIYAKU_KBN.COMMON);

  const [notifyKanryoFlg, setNotifyKanryoFlg] = useState(false);
  const [notifyShabanFlg, setNotifyShabanFlg] = useState(false);

  const [biko, setBiko] = useState(me ? me.biko : "");

  const [notify, setNotify] = useState(NOTIFY_KBN.MAIL);
  const [mail, setMail] = useState("");
  const [telNo, setTelNo] = useState("");

  const [newTmpPaths, setNewTmpPaths] = useState([]);

  // 内部View制御
  const [isViewParett, setViewPallet] = useState(false);
  const [isViewKibobi, setViewKibobi] = useState(false);
  const [isViewkigembi, setViewkigembi] = useState(false);
  const [isViewKikan, setViewKikan] = useState(false);
  const [isViewJikan, setViewJikan] = useState(false);
  const [isViewSonota, setViewSonota] = useState(false);
  const [isViewNgshakaku, setViewNgshakaku] = useState(false);
  const [isViewKiboshashu, setViewKiboshashu] = useState(false);
  const [isViewNieki, setViewNieki] = useState(false);
  const [isViewKaishubashonoshashinNew, setViewKaishubashonoshashinNew] = useState(false);
  const [isViewKaishubashonoshashinExit, setViewKaishubashonoshashinExit] = useState(false);

  const yasumiakeDay = useMemo(() => {
    return getYasumiakeDay(holidays);
  }, [holidays]);

  // 日曜日は避ける
  const secondDay = useMemo(() => {
    return getSecondDay(yasumiakeDay);
  }, [yasumiakeDay]);

  // 表示用パレット種類
  const displayParets = useMemo(() => {
    let label = "選択なし";

    if (!kaishuShubetu || !kaishuShubetu.length) {
      return label;
    }
    label = kaishuShubetu.map((x) => x.shohinName).join("、");
    return label;
  }, [kaishuShubetu]);

  // 表示用希望日
  const displayKiboDate = useMemo(() => {
    let label = "希望なし";
    if (!kiboDateFrom && !kiboDateTo) {
      return label;
    }
    if (kiboDateFrom === kiboDateTo) {
      label = formatDate(kiboDateFrom, "M月D日(ddd)");
      return label;
    }

    if (selectKiboDateType === KIBOBI_KBN.KIGEN_SITEI) {
      label = formatDate(kiboDateTo, "M月D日(ddd)", " 迄");
    } else if (selectKiboDateType === KIBOBI_KBN.KIKAN_SITEI) {
      label = formatDate(kiboDateFrom, "M月D日(ddd)") + " ～ " + formatDate(kiboDateTo, "M月D日(ddd)") + " 間";
    }

    return label;
  }, [kiboDateFrom, kiboDateTo, selectKiboDateType]);

  // 表示用希望時間
  const displayKiboTime = useMemo(() => {
    let label = "希望なし";
    if (kiboTimeBiko) {
      return kiboTimeBiko;
    }
    if (!!!kiboTimeFrom && !!!kiboTimeTo) {
      return "希望なし";
    }
    label = formatDate("20210101" + kiboTimeFrom, "H時") + "～" + formatDate("20210101" + kiboTimeTo, "H時");
    if (label.indexOf("9") !== -1) {
      label = "午前 " + label;
    } else if (label.indexOf("17") !== -1) {
      label = "午後 " + label;
    }
    return label;
  }, [kiboTimeFrom, kiboTimeTo, kiboTimeBiko]);

  // 表示用通知
  const displayNotify = useMemo(() => {
    let label = "";

    if (!notifyKanryoFlg && !notifyShabanFlg) {
      return label;
    }
    if (notify === NOTIFY_KBN.MAIL) {
      label = "携帯電話に通知";
    } else {
      label = "メールに通知";
    }
    return label;
  }, [notify, notifyKanryoFlg, notifyShabanFlg]);

  // 表示用NG車格
  const displayNgShakaku = useMemo(() => {
    let label = "特になし";
    if (!kiboNgShakakuKbns || !kiboNgShakakuKbns.length) return label;
    label = kiboNgShakakuKbns
      .map((kbn) => {
        return ngShakakuList.find((v) => v.kbn === kbn).name;
      })
      .join("、");

    return label;
  }, [kiboNgShakakuKbns, ngShakakuList]);

  // 表示用希望車種
  const displayKiboshashu = useMemo(() => {
    let label = "希望なし";
    if (!!!kiboSharyoKbn || kiboSharyoKbn === "0") return label;
    label = kiboShashuList.find((v) => v.kbn === kiboSharyoKbn).name;
    return label;
  }, [kiboSharyoKbn, kiboShashuList]);

  // 表示用荷役
  const displayNieki = useMemo(() => {
    let label = "どちらでも可";
    if (!!!kiboNiyakuKbn || kiboNiyakuKbn === "0") return label;

    label = niyakuList?.find((v) => v.kbn === kiboNiyakuKbn)?.name;
    return label;
  }, [kiboNiyakuKbn, niyakuList]);

  const handlePalletSelect = useCallback(
    (e) => {
      e.preventDefault();
      setKaishuShubetu(selectKaishuShubetu);
      setViewPallet(false);
    },
    [selectKaishuShubetu]
  );

  const handleSelectKibobi = useCallback(
    (e, kbn) => {
      e.preventDefault();
      setSelectKiboDateType(kbn);
      switch (kbn) {
        case KIBOBI_KBN.NONE:
          setSelectKiboDateFrom("");
          setSelectKiboDateTo("");
          break;
        case KIBOBI_KBN.TWO:
          setSelectKiboDateFrom(yasumiakeDay ? formatDate(yasumiakeDay, "YYYYMMDD") : twoDaysAfter);
          setSelectKiboDateTo(yasumiakeDay ? formatDate(yasumiakeDay, "YYYYMMDD") : twoDaysAfter);
          break;
        case KIBOBI_KBN.THREE:
          setSelectKiboDateFrom(formatDate(secondDay, "YYYYMMDD"));
          setSelectKiboDateTo(formatDate(secondDay, "YYYYMMDD"));
          break;
        default:
          setSelectKiboDateFrom("");
          setSelectKiboDateTo("");
          break;
      }
    },
    [yasumiakeDay, secondDay]
  );

  const onKibobi = useCallback(
    (e) => {
      e.preventDefault();

      setKiboDateFrom(selectKiboDateFrom);
      setKiboDateTo(selectKiboDateTo);
      setViewKibobi(false);
    },
    [selectKiboDateFrom, selectKiboDateTo]
  );

  const onKibobiCheck = useCallback(
    (e) => {
      e.preventDefault();

      if (kiboDateFrom !== selectKiboDateFrom || kiboDateTo !== selectKiboDateTo) {
        setMessage("変更が保存されていませんが\n閉じてよろしいですか？");
        refOkEvent.current = (e) => {
          e.preventDefault();
          setSelectKiboDateFrom(kiboDateFrom);
          setSelectKiboDateTo(kiboDateTo);
          setViewKibobi(false);
          infoModalControl.off();
        };
        infoModalControl.on();
      } else {
        setViewKibobi(false);
      }
    },
    [refOkEvent, kiboDateFrom, selectKiboDateFrom, kiboDateTo, selectKiboDateTo, infoModalControl]
  );

  const handleSelectKiboTime = useCallback((e, kbn) => {
    e.preventDefault();
    setSelectKiboTimeType(kbn);
    switch (kbn) {
      case KIBOTIME_KBN.NONE:
        setSelectKiboTimeFrom("");
        setSelectKiboTimeTo("");
        setSelectKiboTimeBiko("");
        break;
      case KIBOTIME_KBN.AM:
        setSelectKiboTimeFrom("0900");
        setSelectKiboTimeTo("1200");
        setSelectKiboTimeBiko("");
        break;
      case KIBOTIME_KBN.PM:
        setSelectKiboTimeFrom("1300");
        setSelectKiboTimeTo("1700");
        setSelectKiboTimeBiko("");
        break;
      case KIBOTIME_KBN.SONOTA:
        setSelectKiboTimeFrom("");
        setSelectKiboTimeTo("");
        setSelectKiboTimeBiko(e.target.value);
        break;
      default:
        setSelectKiboTimeFrom("");
        setSelectKiboTimeTo("");
        setSelectKiboTimeBiko("");
        break;
    }
  }, []);

  const onKiboTime = useCallback(
    (e) => {
      e.preventDefault();

      setKiboTimeFrom(selectKiboTimeFrom);
      setKiboTimeTo(selectKiboTimeTo);
      setKiboTimeBiko(TB_KiboTimeBiko.current.value);
      setKiboTimeKbn(selectKiboTimeType);
      setViewSonota(false);
      setViewJikan(false);
    },
    [selectKiboTimeFrom, selectKiboTimeTo, selectKiboTimeType]
  );

  const onKiboTimeCheck = useCallback(
    (e) => {
      e.preventDefault();

      if (kiboTimeFrom !== selectKiboTimeFrom || kiboTimeTo !== selectKiboTimeTo) {
        setMessage("変更が保存されていませんが\n閉じてよろしいですか？");
        refOkEvent.current = (e) => {
          e.preventDefault();
          setSelectKiboTimeFrom(kiboTimeFrom);
          setSelectKiboTimeTo(kiboTimeTo);
          setViewJikan(false);
          infoModalControl.off();
        };
        infoModalControl.on();
      } else {
        setViewJikan(false);
      }
    },
    [refOkEvent, kiboTimeFrom, selectKiboTimeFrom, kiboTimeTo, selectKiboTimeTo, infoModalControl]
  );

  const onKiboTimeBikoCheck = useCallback(
    (e) => {
      e.preventDefault();

      if (kiboTimeBiko !== selectKiboTimeBiko) {
        setMessage("変更が保存されていませんが\n閉じてよろしいですか？");
        refOkEvent.current = (e) => {
          e.preventDefault();
          setViewSonota(false);
          setSelectKiboTimeBiko(kiboTimeBiko);
          infoModalControl.off();
        };
        infoModalControl.on();
      } else {
        setViewSonota(false);
      }
    },
    [refOkEvent, kiboTimeBiko, selectKiboTimeBiko, infoModalControl]
  );

  const handleNgShakakuSelect = useCallback(
    (e) => {
      e.preventDefault();
      setKiboNgShakakuKbns(selectNgShakaku);
      setViewNgshakaku(false);
    },
    [selectNgShakaku]
  );

  const handleSelectKiboShashu = useCallback((e, kbn) => {
    e.preventDefault();
    setSelectKiboShashuType(kbn);
  }, []);

  const onKiboShashu = useCallback(
    (e) => {
      e.preventDefault();
      setKiboSharyoKbn(selectKiboShashuType);
      setViewKiboshashu(false);
    },
    [selectKiboShashuType]
  );

  const onKiboShashuCheck = useCallback(
    (e) => {
      e.preventDefault();

      if (kiboSharyoKbn !== selectKiboShashuType) {
        setMessage("変更が保存されていませんが\n閉じてよろしいですか？");
        refOkEvent.current = (e) => {
          e.preventDefault();
          setViewKiboshashu(false);
          infoModalControl.off();
        };
        infoModalControl.on();
      } else {
        setViewKiboshashu(false);
      }
    },
    [refOkEvent, kiboSharyoKbn, selectKiboShashuType, infoModalControl]
  );

  const handleSelectNiyaku = useCallback((e, kbn) => {
    e.preventDefault();
    setSelectNiyaku(kbn);
  }, []);

  const onNieki = useCallback(
    (e) => {
      e.preventDefault();
      setKiboNiyakuKbn(selectNiyaku);
      setViewNieki(false);
    },
    [selectNiyaku]
  );

  const onNiekiCheck = useCallback(
    (e) => {
      e.preventDefault();

      if (kiboNiyakuKbn !== selectNiyaku) {
        setMessage("変更が保存されていませんが\n閉じてよろしいですか？");
        refOkEvent.current = (e) => {
          e.preventDefault();
          setSelectNiyaku(kiboNiyakuKbn);
          setViewNieki(false);
          infoModalControl.off();
        };
        infoModalControl.on();
      } else {
        setViewNieki(false);
      }
    },
    [refOkEvent, kiboNiyakuKbn, selectNiyaku, infoModalControl]
  );

  const onShasinDelete = useCallback(() => {
    refOkEvent.current = (e) => {
      e.preventDefault();
      infoModalControl.off();

      // setSelectItem((pre) => ({ ...pre, files: [] }));
      setNewTmpPaths([]);

      // 決定で削除
      // const newList = [...tehaiList].map((x) => {
      //   if (x.id === newSelectItem.id) {
      //     return newSelectItem;
      //   }
      //   return x;
      // });
      // setTehaiList(newList);
      // setViewKaishubashonoshashinExit(false);
    };
    setMessage("写真を削除します\nよろしいですか？");
    infoModalControl.on();
  }, [infoModalControl]);

  const onShasinKakutei = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        if (selectItem && newTmpPaths) {
          let target = null;
          const newList = [...tehaiList].map((x) => {
            if (x.id === selectItem.id) {
              target = { ...x, files: newTmpPaths };
              return target;
            }
            return x;
          });

          if (target) {
            const res = await axios.put("/api/irai/" + selectItem.id, {
              ...selectItem,
              fileIds: [], // 一旦全部削除
              newTmpPaths: newTmpPaths.map((x) => x.path), //選択した写真を登録
              copyFileIds: [],
            });

            if (res && res.data) {
              setTehaiList(newList);
            }
            setSelectItem(null);
            setNewTmpPaths([]);
          }
        }
        setViewKaishubashonoshashinNew(false);
        setViewKaishubashonoshashinExit(false);
      } catch (error) {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        errorModalControl.on();
      }
    },
    [selectItem, newTmpPaths, tehaiList, errorModalControl]
  );

  const onFileSelect = useCallback(
    async (e) => {
      const file = e.target.files[0];
      try {
        if (file) {
          const formData = new FormData();
          formData.append("file", file, file.name);
          const blob = URL.createObjectURL(file);

          const res = await axios.post("/api/file/tmp", formData);
          if (res && res.data) {
            setNewTmpPaths([{ url: blob, path: res.data[0] }]);
          }
        }
      } catch (error) {
        setErrorMessage("ファイルのアップロードに失敗しました");
        errorModalControl.on();
      }
    },
    [errorModalControl]
  );

  const onBikoValidation = (strKiboBiko) => {
    var length = 0;
    var str = "";
    for (var i = 0; i < strKiboBiko.length; i++) {
      var c = strKiboBiko.charAt(i);
      if (!c.match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/)) {
        length += 1;
      } else {
        length += 2;
      }
      if (length < 33) {
        str += c;
      } else {
        setSelectKiboTimeBiko(str);
        break;
      }
    }
    setSelectKiboTimeBiko(str);
  };

  useEffect(() => {
    setSelectKiboTimeBiko(TB_KiboTimeBiko.current.value);
  }, [changeBiko]);

  const executable = useMemo(() => {
    if (kaishuSuryo <= 0) return false;
    if (!!!kaishuShubetu || kaishuShubetu.length === 0) return false;

    if (notifyKanryoFlg || notifyShabanFlg) {
      if (notify === NOTIFY_KBN.MAIL && !!!mail) return false;
      if (notify === NOTIFY_KBN.TEL && !!!telNo) return false;
    }
    return true;
  }, [kaishuSuryo, kaishuShubetu, notify, notifyKanryoFlg, notifyShabanFlg, mail, telNo]);

  const onIrai = async (e) => {
    e.preventDefault();

    if (!!!executable) return;

    if (me?.loginCode !== "B0000H" && me?.loginCode !== "testsanko" && me?.loginCode !== "11111111") {
      if (kaishuSuryo > 256) {
        setErrorMessage("回収パレット数は256枚以上入力できません。\n1車単位での入力をお願いします。");
        errorModalControl.on();
        return;
      }
    } else {
      if (kaishuSuryo > 300) {
        setErrorMessage("回収パレット数は300枚以上入力できません。\n1車単位での入力をお願いします。");
        errorModalControl.on();
        return;
      }
    }

    let strBiko = biko ? biko : "";

    if (strBiko.length > 0) {
      strBiko = strBiko
        .split("　")
        .join(" ")
        .split("、")
        .join(",")
        .replace(/[！-～]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        });

      if (bytes(strBiko) > 256) {
        setErrorMessage("注意事項・備考は256byte以上入力できません。\n256byte以内での入力をお願いします。");
        errorModalControl.on();
        return;
      }
    }

    // if (bytes(strBiko) > 256) {
    //   setErrorMessage("注意事項・備考は256byte以上入力できません。\n256byte以内での入力をお願いします。");
    //   errorModalControl.on();
    //   return;
    // }

    refOkEvent.current = async (e) => {
      e.preventDefault();
      setKaishuSuryo(0);
      // props.item null は新規
      const f = {
        ...item,
        kiboDateFrom,
        kiboDateTo,
        kiboTimeKbn,
        kiboTimeFrom,
        kiboTimeTo,
        kiboTimeBiko,
        kiboNgShakakuKbns: (kiboNgShakakuKbns || [])?.join(","),
        kiboSharyoKbn,
        kiboNiyakuKbn,
        biko: strBiko,
        notifyKanryoFlg: !!notifyKanryoFlg ? "1" : "0",
        notifyShabanFlg: !!notifyShabanFlg ? "1" : "0",
        mail: notify === NOTIFY_KBN.MAIL ? mail : "",
        tel: notify === NOTIFY_KBN.TEL ? telNo : "",
        newMeisais: kaishuShubetu.map((shubetu, index) => ({
          ...shubetu,
          suryo: index === 0 ? parseInt(kaishuSuryo) : 0,
        })),
        newTmpPaths: newTmpPaths && newTmpPaths.map((x) => x.path),
        henkoFlg: item && item.id ? "1" : "0",
      };

      // 入力情報を保存
      localStorage.setItem(
        "ps-recent-irai-info",
        JSON.stringify({
          ...f,
          kiboDateFrom: "",
          kiboDateTo: "",
          meisais: f.newMeisais,
          expiration: harfYearAfter,
        })
      );

      try {
        // 手配状況から変更時
        if (item && item.id) {
          await axios.put(`/api/irai/${item.id}`, f);
          window.location.reload();
          return;
        }

        // 非ログインの場合、弱いログインキーから取得する
        const weakLoginKey = sessionStorage.getItem("weakLoginKey");

        await axios.post("/api/irai", { ...f, weakLoginKey });
        history.push("/irai-finish", {
          ...f,
          kaishuSuryo,
          kaishuShubetu: displayParets,
          kiboDate: displayKiboDate,
          kiboTime: displayKiboTime,
          notifyKanryo: notifyKanryoFlg && "手配が完了した際に通知を受け取る",
          notifyShaban: notifyShabanFlg && "車番、ドライバー連絡を受け取る",
          biko: strBiko,
          ngshakaku: displayNgShakaku,
          kiboshashu: displayKiboshashu,
          nieki: displayNieki,
        });

        infoModalControl.off();
      } catch (error) {
        const message = createErrorMessage(error);
        setErrorMessage(message);
        infoModalControl.off();
        errorModalControl.on();
      }
    };
    setMessage("こちらの入力内容で\n依頼をしてよろしいですか？");
    infoModalControl.on();
  };

  function bytes(biko) {
    var length = 0;
    for (var i = 0; i < biko.length; i++) {
      var c = biko.charCodeAt(i);
      if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
        length += 1;
      } else {
        length += 2;
      }
    }
    return length;
  }

  useEffect(() => {
    let initItem = item;
    try {
      if (!!!item) {
        const tmp = JSON.parse(localStorage.getItem("ps-recent-irai-info"));
        if (isCompareDay(new Date(), tmp.expiration)) {
          initItem = tmp;
        } else {
          localStorage.removeItem("ps-recent-irai-info");
        }
      }
    } catch {
      console.error("no data: ps-recent-irai-info");
    }

    // state
    if (initItem && Object.keys(initItem).length) {
      setKaishuSuryo(initItem.meisais.reduce((total, curValue) => total + curValue.suryo, 0));
      setSelectKaishuShubetu(initItem.meisais);
      setKaishuShubetu(initItem.meisais);
      // setSelectKiboDateType('')
      setKiboDateFrom(initItem.kiboDateFrom);
      setKiboDateTo(initItem.kiboDateTo);
      setKiboTimeKbn(initItem.kiboTimeKbn);
      setKiboTimeFrom(initItem.kiboTimeFrom);
      setKiboTimeTo(initItem.kiboTimeTo);
      setKiboTimeBiko(initItem.kiboTimeBiko);
      if (initItem.kiboNgShakakuKbns === undefined || initItem.kiboNgShakakuKbns.length < 1) {
        setKiboNgShakakuKbns(initItem.kiboNgShakakuKbns);
      } else {
        setKiboNgShakakuKbns(initItem.kiboNgShakakuKbns.split(","));
      }
      setKiboSharyoKbn(initItem.kiboSharyoKbn);
      setKiboNiyakuKbn(initItem.kiboNiyakuKbn);

      setNotifyKanryoFlg(initItem.notifyKanryoFlg === "0" ? false : true);
      setNotifyShabanFlg(initItem.notifyShabanFlg === "0" ? false : true);
      setBiko(initItem.biko);

      setMail(initItem.mail);
      setTelNo(initItem.tel);

      const val = initItem.email ? NOTIFY_KBN.MAIL : initItem.tel ? NOTIFY_KBN.TEL : NOTIFY_KBN.MAIL;
      setNotify(val);

      if (initItem.kiboDateFrom !== undefined && initItem.kiboDateFrom === initItem.kiboDateTo) {
        if (formatDate(twoDaysAfter, "YYYYMMDD") === initItem.kiboDateFrom) {
          setSelectKiboDateType("1");
        } else if (formatDate(threeDaysAfter, "YYYYMMDD") === initItem.kiboDateFrom) {
          setSelectKiboDateType("2");
        } else {
          setSelectKiboDateType("4");
        }
      } else if (initItem.kiboDateFrom === undefined && initItem.kiboDateTo !== undefined) {
        setSelectKiboDateType("3");
      } else if (isCompareDay(initItem.kiboDateFrom, initItem.kiboDateTo)) {
        setSelectKiboDateType("4");
      } else {
        setSelectKiboDateType("0");
      }

      setSelectKiboDateFrom(initItem.kiboDateFrom);
      setSelectKiboDateTo(initItem.kiboDateTo);
      setSelectKiboShashuType(initItem.kiboSharyoKbn);
      setSelectKiboTimeType(initItem.kiboTimeKbn);
      setSelectKiboTimeFrom(initItem.kiboTimeFrom);
      setSelectKiboTimeTo(initItem.kiboTimeTo);
      setSelectKiboTimeBiko(initItem.kiboTimeBiko);
      if (initItem.kiboNgShakakuKbns === undefined || initItem.kiboNgShakakuKbns.length < 1) {
        // setSelectNgShakaku(initItem.kiboNgShakakuKbns);
        setSelectNgShakaku([]);
      } else {
        setSelectNgShakaku(initItem.kiboNgShakakuKbns.split(","));
      }
      setSelectNiyaku(initItem.kiboNiyakuKbn);
      setNewTmpPaths(initItem.files || initItem.newTmpPaths || []);
    }
  }, [item]);

  return (
    <>
      <div className="main">
        {isHavi && <MainNaviPc current="/irai" />}
        <div className="main__main">
          <div className="main__main-header">
            {isHavi && (
              <>
                <MainNavi current="/irai" />
                <div className="hdg --h1">
                  <span className="hdg__title">回収依頼</span>

                  <span className="hdg__note ico --calendar" style={{ display: "none" }}>
                    定期回収 [ 月、木 午前 ]
                  </span>

                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScttS0dTM7SkSKJwiDaKNBpbgjZN5YmAm4Zt_b05ei3RLLyDw/viewform?usp=sf_link"
                    className="hdg__note"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    パレット回収アンケート実施中
                    <img src="/assets/Images/pic_another_tab.svg" alt="" />
                  </a>
                </div>
              </>
            )}
          </div>
          <div>
            <a
              href={"/assets/r03sdhy219/【SPR】2024年　年末年始休業案内.pdf"}
              target="_blank"
              class="hdg__note2"
              rel="noreferrer"
            >
              <span>年末年始の休業ご案内</span>
            </a>
          </div>
          <div className="main__main-contents">
            <div className="inner">
              <div className="form" style={{ marginTop: isHavi ? 0 : 64 }}>
                <TextInput
                  title="回収パレット数"
                  type="text"
                  inputmode="numeric"
                  value={kaishuSuryo !== 0 ? kaishuSuryo : ""}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d*$/.test(val)) {
                      setKaishuSuryo(val);
                    } else {
                      setKaishuSuryo((prev) => prev);
                    }
                  }}
                  unitLayout={<span className="form-detail-layout__unit">枚</span>}
                  style={{ verticalAlign: "bottom" }}
                  // validation={{ type: "number", max: 256, min: 0, unit: "枚"}}
                />
                <a
                  href="javascript:void(0);"
                  className="form-layout paneOpen"
                  rel="parettonoshurui"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!!!shohins || !!!shohins.length) {
                      setErrorMessage("パレットが選択できません。\nログインしてください。");
                      errorModalControl.on();
                      return;
                    }
                    setViewPallet(true);
                  }}
                >
                  <div className="form-layout__title">
                    <span>パレットの種類</span>
                  </div>
                  <div className="form-layout__detail">
                    <div className="form-detail-layout">
                      <span className="form-detail-layout__value">
                        <span>{displayParets}</span>
                      </span>
                      <span className="form-detail-layout__link"></span>
                    </div>
                  </div>
                </a>

                <a
                  href="#"
                  className="form-layout paneOpen"
                  rel="kaishukibobi"
                  onClick={(e) => {
                    e.preventDefault();
                    setViewKibobi(true);
                  }}
                >
                  <div className="form-layout__title">
                    <span>回収希望日</span>
                  </div>
                  <div className="form-layout__detail">
                    <div className="form-detail-layout">
                      <span className="form-detail-layout__value">
                        <span>{displayKiboDate}</span>
                      </span>
                      <span className="form-detail-layout__link"></span>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  className="form-layout paneOpen"
                  rel="kaishukibojikan"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!!!kiboTimeList || !!!kiboTimeList.length) {
                      setErrorMessage("希望時間が選択できません。\nログインしてください。");
                      errorModalControl.on();
                      return;
                    }
                    setViewJikan(true);
                  }}
                >
                  <div className="form-layout__title">
                    <span>回収希望時間</span>
                  </div>
                  <div className="form-layout__detail">
                    <div className="form-detail-layout">
                      <span className="form-detail-layout__value">
                        <span>{displayKiboTime}</span>
                      </span>
                      <span className="form-detail-layout__link"></span>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  className="form-layout paneOpen"
                  rel="kaishubashonoshashin-new"
                  onClick={(e) => {
                    e.preventDefault();
                    if (newTmpPaths.length === 0) {
                      setViewKaishubashonoshashinNew(true);
                    } else {
                      setViewKaishubashonoshashinExit(true);
                    }
                  }}
                >
                  <div className="form-layout__title">
                    <span>回収場所の写真</span>
                  </div>
                  <div className="form-layout__detail">
                    <div className="form-detail-layout">
                      {newTmpPaths.length > 0 ? (
                        <span class="form-detail-layout__pic">
                          <span>
                            <img src={newTmpPaths[0].url} alt="" />
                          </span>
                        </span>
                      ) : (
                        <span className="form-detail-layout__value --empty">
                          <span>選択する(任意)</span>
                        </span>
                      )}
                      <span className="form-detail-layout__link"></span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="padding-for-sp">
                <div className="hdg  --h2">
                  <div className="hdg__title">車両設定</div>
                </div>
              </div>
              <div className="form">
                <a
                  href="javascript:void(0);"
                  className="form-layout paneOpen"
                  rel="ngshakaku"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!!!ngShakakuList || !!!ngShakakuList.length) {
                      setErrorMessage("NG車格が選択できません。\nログインしてください。");
                      errorModalControl.on();
                      return;
                    }
                    setViewNgshakaku(true);
                  }}
                >
                  <div className="form-layout__title">
                    <span>NG車格</span>
                  </div>
                  <div className="form-layout__detail">
                    <div className="form-detail-layout">
                      <span className="form-detail-layout__value">
                        <span>{displayNgShakaku}</span>
                      </span>
                      <span className="form-detail-layout__link"></span>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  className="form-layout paneOpen"
                  rel="kiboshashu"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!!!kiboShashuList || !!!kiboShashuList.length) {
                      setErrorMessage("希望車種が選択できません。\nログインしてください。");
                      errorModalControl.on();
                      return;
                    }
                    setViewKiboshashu(true);
                  }}
                >
                  <div className="form-layout__title">
                    <span>希望車種</span>
                  </div>
                  <div className="form-layout__detail">
                    <div className="form-detail-layout">
                      <span className="form-detail-layout__value">
                        <span>{displayKiboshashu}</span>
                      </span>
                      <span className="form-detail-layout__link"></span>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  className="form-layout paneOpen"
                  rel="nieki"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!!!niyakuList || !!!niyakuList.length) {
                      setErrorMessage("荷役が選択できません。\nログインしてください。");
                      errorModalControl.on();
                      return;
                    }
                    setViewNieki(true);
                  }}
                  hidden
                >
                  <div className="form-layout__title">
                    <span>荷役</span>
                  </div>
                  <div className="form-layout__detail">
                    <div className="form-detail-layout">
                      <span className="form-detail-layout__value">
                        <span>{displayNieki}</span>
                      </span>
                      <span className="form-detail-layout__link"></span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="padding-for-sp">
                <div className="hdg  --h2">
                  <div className="hdg__title">通知の設定</div>
                  {(notifyKanryoFlg || notifyShabanFlg) && (
                    <div
                      className="hdg__btn"
                      id="jsType-NotificationType"
                      data-type="mail"
                      style={{ display: "block" }}
                    >
                      <a
                        href="javascript:void(0);"
                        className="btn --gry-secondary --h-xs btn--notification"
                        style={{ display: "block" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setNotify((prev) => (prev === NOTIFY_KBN.MAIL ? NOTIFY_KBN.TEL : NOTIFY_KBN.MAIL));
                        }}
                      >
                        <span>{displayNotify}</span>
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="form">
                <div className="form-layout">
                  <div className="form-layout__detail">
                    <div className="form-detail-layout__checkbox">
                      <label>
                        <input
                          type="checkbox"
                          className="cbx-notification"
                          name="notification"
                          checked={notifyKanryoFlg}
                          onChange={(e) => {
                            setNotifyKanryoFlg((prev) => !prev);
                          }}
                        />
                        <span></span>
                        <span>手配が完了した際に通知を受け取る</span>
                      </label>
                    </div>
                    <div className="form-detail-layout__checkbox">
                      <label>
                        <input
                          type="checkbox"
                          className="cbx-notification"
                          name="notification"
                          checked={notifyShabanFlg}
                          onClick={(e) => {
                            setNotifyShabanFlg((prev) => !prev);
                          }}
                        />
                        <span></span>
                        <span>車番、ドライバー連絡を受け取る</span>
                      </label>
                    </div>
                  </div>
                </div>
                {(notifyKanryoFlg || notifyShabanFlg) && notify === NOTIFY_KBN.MAIL && (
                  <TextInput
                    addClass="form-layout--mail"
                    style={{ display: "flex" }}
                    title="メールアドレス"
                    type="text"
                    inputmode="email"
                    placeholder="例）irai@pallet-station.com(必須)"
                    value={mail}
                    onChange={(e) => {
                      setMail(e.target.value);
                    }}
                    unitLayout={null}
                  />
                )}
                {(notifyKanryoFlg || notifyShabanFlg) && notify === NOTIFY_KBN.TEL && (
                  <TextInput
                    addClass="form-layout--mobilephone"
                    style={{ display: "flex" }}
                    title="携帯電話番号"
                    type="text"
                    inputmode="tel"
                    placeholder="例）09000000000(必須)"
                    value={telNo}
                    onChange={(e) => {
                      const tel = e.target.value;

                      if (tel === "" || /^(\d|-){1,13}$/.test(tel)) {
                        setTelNo(tel);
                      }
                    }}
                    unitLayout={null}
                  />
                )}
              </div>
              <div className="padding-for-sp">
                <div className="hdg --h2">
                  <div className="hdg__title">注意事項・備考</div>
                </div>
              </div>
              <div className="form">
                <div className="form-layout">
                  <div className="form-layout__full">
                    <textarea
                      name=""
                      placeholder="注意事項等(任意 50文字程度)&#13;&#10;&#13;&#10;例）回収場所は第2倉庫になります。"
                      value={biko}
                      onChange={(e) => setBiko(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="btn-layout --col-1 --sticky">
                <div>
                  <a
                    href="#"
                    className="btn --primary"
                    disabled={!executable}
                    id="jsSubmit-Kaishuirai"
                    onClick={onIrai}
                  >
                    <span>{`${item && item.id ? "内容の変更を依頼する" : "回収を依頼する"}`}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="main__main-cover"></div>
        </div>
      </div>

      <div className={`pane pane--parettonoshurui ${isViewParett && "isOpen"}`} style={{ zIndex: 99999 }}>
        <div className="pane__header --sticky">
          <a
            href="#"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setViewPallet(false);
            }}
          />

          <div className="pane__header-title">パレットの種類</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="padding-for-sp">
              <div className="txt --blk-300 --fz-ss --bold">回収するパレットの種類を選択してください</div>
            </div>
            <div className="pallets">
              {isViewParett &&
                shohins.map((shohin) => (
                  <CheckBox
                    key={shohin.shohinCode}
                    mainClass="pallet"
                    selected={selectKaishuShubetu.some((x) => x.shohinCode === shohin.shohinCode)}
                    onChange={(e) => {
                      const currentChecked = e.target.checked;
                      setSelectKaishuShubetu((prev) => {
                        if (currentChecked) {
                          if (prev.some((x) => x.shohinCode === shohin.shohinCode)) {
                            return prev;
                          } else {
                            return [...prev, shohin];
                          }
                        } else {
                          return [...prev].filter((x) => x.shohinCode !== shohin.shohinCode);
                        }
                      });
                    }}
                    label={shohin.shohinName}
                    imagePath={shohin.url}
                  />
                ))}
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0);" className="btn --primary paneClose" onClick={(e) => handlePalletSelect(e)}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`pane pane--kaishukibobi ${isViewKibobi && "isOpen"}`} style={{ zIndex: 99999 }}>
        <div className="pane__header">
          <a href="#" className="pane__header-back paneClose" onClick={(e) => onKibobiCheck(e)} />

          <div className="pane__header-title" id="jsGroup-Kaishukibobi" data-type={selectKiboDateType.toString()}>
            希望日を指定
          </div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="btn-layout --stack --w-s mt--0">
              <div>
                <a
                  href="#"
                  className={
                    "btn " +
                    (selectKiboDateType === KIBOBI_KBN.NONE ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibobi"
                  }
                  onClick={(e) => handleSelectKibobi(e, KIBOBI_KBN.NONE)}
                >
                  <span>希望なし</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboDateType === KIBOBI_KBN.TWO ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibobi"
                  }
                  onClick={(e) => handleSelectKibobi(e, KIBOBI_KBN.TWO)}
                >
                  <span>
                    {yasumiakeDay ? "" : "明後日　"}
                    <span className="jsLabel2DayAfter">{formatDate(yasumiakeDay, "M月D日(ddd)")}</span>
                  </span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboDateType === KIBOBI_KBN.THREE ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibobi"
                  }
                  onClick={(e) => handleSelectKibobi(e, KIBOBI_KBN.THREE)}
                >
                  <span>
                    {yasumiakeDay ? "" : "明々後日　"}
                    <span className="jsLabel3DayAfter">{formatDate(secondDay, "M月D日(ddd)")}</span>
                  </span>
                </a>
              </div>
            </div>
            <div className="btn-layout --col-2 --w-s">
              <div>
                <a
                  href="#"
                  className={
                    "btn " +
                    (selectKiboDateType === KIBOBI_KBN.KIGEN_SITEI ? "--secondary " : "--wht-secondary ") +
                    "--h-s btn-group-kaishukibobi paneOpen"
                  }
                  rel="kigembishitei"
                  onClick={(e) => {
                    e.preventDefault();
                    setViewkigembi(true);
                  }}
                >
                  <span>期限日指定</span>
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className={
                    "btn " +
                    (selectKiboDateType === KIBOBI_KBN.KIKAN_SITEI ? "--secondary " : "--wht-secondary ") +
                    "--h-s btn-group-kaishukibobi paneOpen"
                  }
                  rel="kikanhanishitei"
                  onClick={(e) => {
                    e.preventDefault();
                    setViewKikan(true);
                  }}
                >
                  <span>期間範囲指定</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0);" className="btn --primary paneClose" onClick={(e) => onKibobi(e)}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--kigembishitei ${isViewkigembi && "isOpen"}`} style={{ zIndex: 99999 }}>
        <div className="pane__header">
          <a
            href="#"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setViewkigembi(false);
            }}
          />

          <div className="pane__header-title">期限日指定</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="txt --blk-300 --fz-ss --bold padding-for-sp inner-s">回収期限の日付を選択してください</div>
            <KigenCalendarPanel
              {...{
                kiboDateFrom,
                setKiboDateFrom,
                kiboDateTo,
                setKiboDateTo,
                setSelectKiboDateFrom,
                setSelectKiboDateTo,
                selectKiboDateType,
                setSelectKiboDateType,
              }}
            />
          </div>

          <div className="btn-layout --col-1 --stack --sticky">
            {/* <div className="txt --secondary --fz-m --bold ta--c">
              <span id="calendar-kigenbi-date"></span> 迄
            </div> */}

            <div>
              <a
                href="javascript:void(0);"
                className="btn --primary paneClose"
                id="jsSubmit-Kigembishitei"
                onClick={(e) => {
                  e.preventDefault();
                  setViewkigembi(false);
                  setViewKibobi(false);
                }}
              >
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--kikanhanishitei ${isViewKikan && "isOpen"}`} style={{ zIndex: 99999 }}>
        <div className="pane__header">
          <a
            href="#"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setViewKikan(false);
            }}
          />

          <div className="pane__header-title">期間範囲指定</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="txt --blk-300 --fz-ss --bold padding-for-sp inner-s">
              回収可能な期間の初めと終わりを選択してください
            </div>
            <KikanCalendarPanel
              {...{
                kiboDateFrom,
                setKiboDateFrom,
                kiboDateTo,
                setKiboDateTo,
                selectKiboDateType,
                setSelectKiboDateType,
              }}
            />
          </div>
          <div className="btn-layout --col-1 --stack --sticky">
            <div className="txt --secondary --fz-m --bold ta--c">
              <span id="calendar-hani-date">&nbsp;</span>
            </div>

            <div>
              <a
                href="javascript:void(0);"
                className="btn --primary paneClose"
                id="jsSubmitKikanhanishitei"
                onClick={(e) => {
                  e.preventDefault();
                  setViewKikan(false);
                  setViewKibobi(false);
                  setSelectKiboDateType(KIBOBI_KBN.KIKAN_SITEI);
                }}
              >
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`pane pane--kaishukibojikan ${isViewJikan && "isOpen"}`} style={{ zIndex: 99999 }}>
        <div className="pane__header">
          <a href="#" className="pane__header-back paneClose" onClick={(e) => onKiboTimeCheck(e)} />

          <div className="pane__header-title" id="jsGroup-Kaishukibojikan" data-type="0">
            回収希望時間
          </div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="btn-layout --stack --w-s mt--0">
              <div>
                <a
                  href="#"
                  className={
                    "btn " +
                    (selectKiboTimeType === KIBOTIME_KBN.NONE ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibojikan"
                  }
                  onClick={(e) => handleSelectKiboTime(e, KIBOTIME_KBN.NONE)}
                >
                  <span>希望なし</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboTimeType === KIBOTIME_KBN.AM ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibojikan"
                  }
                  onClick={(e) => handleSelectKiboTime(e, KIBOTIME_KBN.AM)}
                >
                  <span>午前　9 - 12時</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboTimeType === KIBOTIME_KBN.PM ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibojikan"
                  }
                  onClick={(e) => handleSelectKiboTime(e, KIBOTIME_KBN.PM)}
                >
                  <span>午後　13 - 17時</span>
                </a>
              </div>
            </div>
            <div className="btn-layout --col-1">
              <div>
                <a
                  href="#"
                  className={
                    "btn " +
                    (selectKiboTimeType === KIBOTIME_KBN.SONOTA ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kaishukibojikan --h-s --w-ss paneOpen"
                  }
                  rel="sonotajikantaishitei"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectKiboTime(e, KIBOTIME_KBN.SONOTA);
                    setViewSonota(true);
                  }}
                >
                  <span>その他時間指定</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0);" className="btn --primary paneClose" onClick={(e) => onKiboTime(e)}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--sonotajikantaishitei ${isViewSonota && "isOpen"}`} style={{ zIndex: 99999 }}>
        <div className="pane__header">
          <a href="#" className="pane__header-back paneClose" onClick={(e) => onKiboTimeBikoCheck(e)} />

          <div className="pane__header-title">その他時間指定</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="form">
              <div className="form-layout">
                <div className="form-layout__full">
                  <textarea
                    className="textarea --h-l"
                    name=""
                    placeholder="ご希望時間（16文字程度）&#13;&#10;&#13;&#10;例）11時から～14時の間希望。"
                    //&#13;&#10;ただし12時～13時は昼休憩で席外しています。"
                    value={selectKiboTimeBiko}
                    ref={TB_KiboTimeBiko}
                    onChange={(e) => onBikoValidation(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a
                href="javascript:void(0);"
                className="btn --primary paneClose"
                id="jsSubmitSonotajikantaishitei"
                onClick={(e) => onKiboTime(e)}
              >
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <input type="file" ref={inputFileRef} hidden accept="image/*" onChange={onFileSelect} />
      <div
        className={`pane pane--kaishubashonoshashin pane--kaishubashonoshashin-new ${
          isViewKaishubashonoshashinNew && "isOpen"
        }`}
        style={{ zIndex: 99999 }}
      >
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setNewTmpPaths([]);
              setViewKaishubashonoshashinNew(false);
            }}
          ></a>

          <div className="pane__header-title">回収場所の写真</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="selected-picture">
              <div className="selected-picture__pic">
                {/* checkpoint プレビュー */}
                {newTmpPaths.length > 0 && <img src={newTmpPaths[0].url} alt="" />}
              </div>
            </div>

            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">登録写真から選択</div>
              </div>
            </div>
            <div className="pictures --selectable">
              {files.map((place) => (
                <div
                  key={place.url}
                  className={`picture ${newTmpPaths.length > 0 && place.path === newTmpPaths[0].path && "--current"}`}
                >
                  <a
                    href="javascript:void(0);"
                    className="picture__pic"
                    onClick={(e) => {
                      e.preventDefault();
                      setNewTmpPaths([place]);
                    }}
                  >
                    <img src={place.url} alt="" />
                  </a>
                </div>
              ))}
            </div>
            <div className="btn-layout --stack --w-s">
              <div>
                <a
                  href="#"
                  className="btn --wht-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    if (inputFileRef.current) {
                      inputFileRef.current.click();
                    }
                  }}
                >
                  <span>写真を選択する</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="" className="btn --primary" onClick={onShasinKakutei}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`pane pane--kaishubashonoshashin pane--kaishubashonoshashin-exist ${
          isViewKaishubashonoshashinExit && "isOpen"
        }`}
      >
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setViewKaishubashonoshashinExit(false);
            }}
          ></a>

          <div className="pane__header-title">回収場所の写真</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="selected-picture">
              <div className="selected-picture__pic">
                <a
                  href="javascript:void(0);"
                  className="modalPictureOpen"
                  rel={newTmpPaths.length > 0 ? newTmpPaths[0].url : ""}
                >
                  {newTmpPaths.length > 0 && <img src={newTmpPaths[0].url} alt="" />}
                </a>
              </div>
              <div className="selected-picture__delete">
                <a
                  href="javascript:void(0);"
                  className="ico --trash link --secondary --fz-s modalOpen"
                  rel="delete-photo"
                  onClick={onShasinDelete}
                >
                  削除する
                </a>
              </div>
            </div>

            <div className="padding-for-sp">
              <div className="hdg  --h2">
                <div className="hdg__title">登録写真から選択</div>
              </div>
            </div>
            <div className="pictures --selectable">
              {files.map((place) => (
                <div
                  key={place.id}
                  className={`picture ${newTmpPaths.length > 0 && place.path === newTmpPaths[0].path && "--current"}`}
                >
                  <a
                    href="javascript:void(0);"
                    className="picture__pic"
                    onClick={(e) => {
                      e.preventDefault();
                      setNewTmpPaths([place]);
                    }}
                  >
                    <img src={place.url} alt="" />
                  </a>
                </div>
              ))}
            </div>

            <div className="btn-layout --stack --w-s">
              <div>
                <a
                  href="#"
                  className="btn --wht-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    if (inputFileRef.current) {
                      inputFileRef.current.click();
                    }
                  }}
                >
                  <span>写真を選択する</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="" className="btn --primary" onClick={onShasinKakutei}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`pane pane--ngshakaku ${isViewNgshakaku && "isOpen"}`} style={{ zIndex: 99999 }}>
        <div className="pane__header">
          <a
            href="javascript:void(0);"
            className="pane__header-back paneClose"
            onClick={(e) => {
              e.preventDefault();
              setViewNgshakaku(false);
            }}
          ></a>

          <div className="pane__header-title">NG車格</div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="padding-for-sp">
              <div className="txt --blk-300 --fz-ss --bold">許可できない車格がある場合は選択してください</div>
            </div>
            <div className="ngvehicles">
              {isViewNgshakaku &&
                ngShakakuList.map(({ kbn, name }) => (
                  <CheckBox
                    key={kbn}
                    mainClass="ngvehicle"
                    selected={selectNgShakaku?.some((x) => x === kbn)}
                    onChange={(e) => {
                      const currentChecked = e.target.checked;
                      if (!!!selectNgShakaku) {
                        setSelectNgShakaku([kbn]);
                        return;
                      }
                      setSelectNgShakaku((prev) => {
                        if (currentChecked) {
                          if (prev?.some((x) => x === kbn)) {
                            return prev;
                          } else {
                            return [...prev, kbn];
                          }
                        } else {
                          return [...prev].filter((x) => x !== kbn);
                        }
                      });
                    }}
                    label={name}
                  />
                ))}
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a
                href="javascript:void(0);"
                className="btn --primary paneClose"
                onClick={(e) => handleNgShakakuSelect(e)}
              >
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--kiboshashu ${isViewKiboshashu && "isOpen"}`} style={{ zIndex: 99999 }}>
        <div className="pane__header">
          <a href="javascript:void(0);" className="pane__header-back paneClose" onClick={onKiboShashuCheck}></a>

          {/* <!-- data-typeで指定したindexのボタンが選択色になります --> */}
          <div className="pane__header-title" id="jsGroup-Kiboshashu" data-type="0">
            希望車種
          </div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="btn-layout --stack --w-s mt--0">
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboShashuType === KIBOSHASHU_KBN.NONE ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kiboshashu"
                  }
                  onClick={(e) => handleSelectKiboShashu(e, KIBOSHASHU_KBN.NONE)}
                >
                  <span>希望なし</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboShashuType === KIBOSHASHU_KBN.WING ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kiboshashu"
                  }
                  onClick={(e) => handleSelectKiboShashu(e, KIBOSHASHU_KBN.WING)}
                >
                  <span>ウイング</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboShashuType === KIBOSHASHU_KBN.ROLLER ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kiboshashu"
                  }
                  onClick={(e) => handleSelectKiboShashu(e, KIBOSHASHU_KBN.ROLLER)}
                >
                  <span>ローラー</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboShashuType === KIBOSHASHU_KBN.JORDER ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kiboshashu"
                  }
                  onClick={(e) => handleSelectKiboShashu(e, KIBOSHASHU_KBN.JORDER)}
                >
                  <span>ジョルダー</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectKiboShashuType === KIBOSHASHU_KBN.KANNON ? "--secondary " : "--wht-secondary ") +
                    "btn-group-kiboshashu"
                  }
                  onClick={(e) => handleSelectKiboShashu(e, KIBOSHASHU_KBN.KANNON)}
                >
                  <span>
                    観音開き車
                    <br />
                    (ハンドリフトにて積み付け)
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0);" className="btn --primary paneClose" onClick={onKiboShashu}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`pane pane--nieki ${isViewNieki && "isOpen"}`} style={{ zIndex: 99999 }}>
        <div className="pane__header">
          <a href="javascript:void(0);" className="pane__header-back paneClose" onClick={onNiekiCheck}></a>

          {/* <!-- data-typeで指定したindexのボタンが選択色になります --> */}

          <div className="pane__header-title" id="jsGroup-Nieki" data-type="2">
            荷役
          </div>
        </div>
        <div className="pane__main">
          <div className="inner">
            <div className="btn-layout --stack --w-s mt--0">
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectNiyaku === NIYAKU_KBN.LEASE ? "--secondary " : "--wht-secondary ") +
                    "btn-group-nieki"
                  }
                  onClick={(e) => handleSelectNiyaku(e, NIYAKU_KBN.LEASE)}
                >
                  <span>三甲リース</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectNiyaku === NIYAKU_KBN.JISHA ? "--secondary " : "--wht-secondary ") +
                    "btn-group-nieki"
                  }
                  onClick={(e) => handleSelectNiyaku(e, NIYAKU_KBN.JISHA)}
                >
                  <span>自社</span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0);"
                  className={
                    "btn " +
                    (selectNiyaku === NIYAKU_KBN.COMMON ? "--secondary " : "--wht-secondary ") +
                    "btn-group-nieki"
                  }
                  onClick={(e) => handleSelectNiyaku(e, NIYAKU_KBN.COMMON)}
                >
                  <span>どちらでも可</span>
                </a>
              </div>
            </div>
          </div>
          <div className="btn-layout --col-1 --sticky">
            <div>
              <a href="javascript:void(0);" className="btn --primary paneClose" onClick={onNieki}>
                <span>決定</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* モーダル */}
      <InfoModal isOpen={isInfoModal} control={infoModalControl} message={message} onOk={refOkEvent.current} />
      <ErrorModal isOpen={isErrorModal} control={errorModalControl} title="エラー" message={errorMessage} />
    </>
  );
});

export default IraiForm;
